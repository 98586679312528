const { Hasura } = require('hasura-om');
const { generateTables } = require('./hasura/tables');

function createHasuraInstance(url, token) {
	var orm = new Hasura({
		graphqlUrl: url,
		jwt: token,
		wsConnectionSettings: {
			webSocketImpl: WebSocket,
		},
	});

	orm = generateTables(orm);

	//define repeatable parts
	let aggCount = ['aggregate', ['count']];
	let imageFields = ['cdn_url', 'image_path'];

	let baseProfileExtension = [orm.table('user').fragment('base'), ['profile_image', imageFields]];

	let baseProjectExtension = [
		orm.table('project').fragment('base'),
		['tasks_aggregate', [aggCount]],
		['timers', [orm.table('timer').fragment('base')]],
		['owner', [...baseProfileExtension]],
		['guests', [orm.table('project_guest').fragment('base'), ['user', [...baseProfileExtension]]]],
		['favorited_by', [orm.table('project_favorite').fragment('base')]],
		`
			users_categories {
				id
				category {
					${orm.table('project_category').fragment('base').fields()}
				}
			}
		`,
		['color', [orm.table('project_color').fragment('base')]],
	];

	let baseTaskExtension = [
		orm.table('task').fragment('base'),
		['subtasks_aggregate', [aggCount]],
		['owner', [...baseProfileExtension]],
	];

	//define custom fragments
	//user
	orm.table('user').createFragment('public', [...baseProfileExtension]);
	orm
		.table('user_private')
		.createFragment('public', [orm.table('user_private').fragment('base'), ['profile_image', imageFields]]);

	//project
	orm
		.table('project')
		.createFragment('list', [...baseProjectExtension, ['tasks', ['is_finished', ['subtasks', ['is_finished']]]]]);
	orm
		.table('project')
		.createFragment('w_tasks', [
			...baseProjectExtension,
			['tasks(order_by: {updated_at: desc})', [...baseTaskExtension, ['subtasks', ['is_finished']]]],
		]);
	orm
		.table('project')
		.createFragment('id_title', [
			'id',
			'title'
		]);
	

	//task
	orm.table('task').createFragment('w_subtasks', [
		...baseTaskExtension,
		['subtasks(order_by: {updated_at: desc})', [orm.table('subtask').fragment('base')]],
		['timers', [orm.table('timer').fragment('base')]],
		`
				project {
					guests {
						user {
							${orm.table('user').fragment('public').fields()}
						}
					}
				}
			`,
	]);
	orm
		.table('task')
		.createFragment('id_title', [
			'id',
			'title'
		]);
	
	
	// subtask
	orm
		.table('subtask')
		.createFragment('id_title', [
			'id',
			'title'
		]);
	

	//timer
	orm.table('timer').createFragment('extended', [
		orm.table('timer').fragment('base'),
		['project', ['title']],
		['task', ['title']],
		['subtask', ['title']],
	]);

	return orm;
}

export default createHasuraInstance;
