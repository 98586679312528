export default {
    path: '/project',
    component: () => import('@/views/project/Layout'),
    children: [
        {
            name: 'ProjectList',
            path: 'list',
            component: () => import('@/views/project/List'),
            meta: {
                authRequired: true
            },
        },
        {
            name: 'ProjectListFinished',
            path: 'finished',
            component: () => import('@/views/project/ListFinished'),
            meta: {
                authRequired: true
            },
        },
        {
            name: 'ProjectCategory',
            path: 'category/:category',
            component: () => import('@/views/project/ListCategory'),
            meta: {
                authRequired: true
            },
        },
        {
            name: 'ProjectView',
            path: ':id',
            props: true,
            component: () => import('@/views/project/View'),
            meta: {
                authRequired: true
            },
        },

        {
            name: 'TaskView',
            path: ':id/:task_id',
            props: true,
            component: () => import('@/views/project/TaskView'),
            meta: {
                authRequired: true
            },
        },
    ]
}