export default {
	namespaced: true,
	state: {
        image: null,
        invite2project: null
	},
	mutations: {
        set_image: (state, modal) => (state.image = modal),
        set_invite2project: (state, modal) => (state.invite2project = modal),
    },
    actions: {
        open_image: ({ state }, image) => {
            state.image.openModal(image)
        },
        open_invite2project: ({ state }, project_id) => {
            state.invite2project.openModal(project_id)
        }
    }
};
