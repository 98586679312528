export default {
    mounted() {
        this.$nextTick(() => {
            this.imageModalOpenEvents()
        })
    },
    methods: {
        imageModalOpenEvents() {
			$('body').on('click', 'img.image-blot', (el) => {
				let img = $(el.target)
				this.$store.dispatch('modal/open_image', {
					cdn_url: img.data('cdn'),
					image_path: img.data('path')
				})
			})
		},
    }
}