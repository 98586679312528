import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

import auth from './auth';
import settings from './settings';
import timer from './timer';
import navigation from './navigation';
import modal from './modal';

export default new Vuex.Store({
	state: {
		$orm: null
	},
	mutations: {
		set_orm: (state, orm) => state.$orm = orm
	},
	actions: {
		init: async ({ commit, dispatch }, $orm) => {
			commit('set_orm', $orm)

			await dispatch('auth/refresh_token');
			await dispatch('auth/get_user');
			await dispatch('timer/init');
		},
		handle_event: async ({ dispatch }, { event, data }) => {
			//if (event == 'load_profile') dispatch('auth/get_profile');
		},
	},
	modules: {
		auth,
		settings,
		timer,
		navigation,
		modal
	},
	plugins: [
		createPersistedState({
			paths: ['auth', 'settings', 'timer'],
		}),
	],
});
