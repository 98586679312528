import Vue from 'vue';
import router from './router';
import store from './store';

import App from './App.vue';

import { $http } from './plugins/axios';
import createHasuraInstance from './plugins/hasura';
import log from './plugins/log';

import './plugins/theme';
import './plugins/cdn';
import './plugins/masonry';

Vue.prototype.$http = $http;

(async () => {
	//get json config
	try {
		await store.dispatch('settings/get_settings');
	} catch (error) {
		log.error('main', error);
		return alert(error.message);
	}

	const $orm = createHasuraInstance(store.state.settings.graphql_url, store.state.auth.jwt_token)
	Vue.prototype.$orm = $orm;

	$http.defaults.baseURL = store.state.settings.backend_url;

	//get current session status
	await store.dispatch('init', $orm);

	//start vue
	Vue.config.productionTip = false;
	new Vue({
		router,
		store,
		render: (h) => h(App),
	}).$mount('#app');
})();
