export default {
    path: '/settings',
    component: () => import('@/views/settings/Layout'),
    children: [
        {
            name: 'SettingsProfile',
            path: '',
            component: () => import('@/views/settings/blocks/Profile'),
            meta: {
                authRequired: true,
            },
        },
        {
            name: 'SettingsSecurity',
            path: 'security',
            component: () => import('@/views/settings/blocks/Security'),
            meta: {
                authRequired: true,
            },
        },
        {
            name: 'SettingsNotification',
            path: 'notification',
            component: () => import('@/views/settings/blocks/Notification'),
            meta: {
                authRequired: true,
            },
        },
    ],
}