import Vue from 'vue';

const canUseWebP = (function() {
	var elem = document.createElement('canvas');

	if (!!(elem.getContext && elem.getContext('2d'))) {
		// was able or not to get WebP representation
		return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
	} else {
		// very old browser like IE 8, canvas not supported
		return false;
	}
})();

/*
	default: 'absolute path to placeholder image'
	image: {
		cdn_url
		image_path
	}

	//modifiers
	//w,h,r ...

	all
	mobile
	tablet
	pc
*/
Vue.prototype.$cdn = function(options) {
	if (!options.image) return options.default;

	let modifiers = options.all || {};

	let isMobile = screen.width <= 768;
	let isTablet = screen.width > 768 && screen.width < 992;

	if (isMobile && options.mobile) modifiers = options.mobile;
	else if (isTablet && options.tablet) modifiers = options.tablet;
	else if (options.pc) modifiers = options.pc;

	if (!modifiers.format && canUseWebP) modifiers.format = 'webp';

	let modify = [];
	Object.keys(modifiers).forEach(modifier => {
		modify.push(`${modifier}=${modifiers[modifier]}`);
	});

	let url = `${options.image.cdn_url}/i/`;
	if (modify && modify.length) url = url + modify.join(',') + '/_/';

	return url + options.image.image_path;
};
