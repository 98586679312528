import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/';

Vue.use(VueRouter);

import project from './project.js'
import auth from './auth.js'
import settings from './settings.js'
import profile from './profile.js'
import timer from './timer.js'

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		//main layout
		{
			path: '/',
			component: () => import('@/views/MainLayout'),
			children: [
				{
					name: 'Landing',
					path: '/',
					component: () => import('@/views/index/Landing'),
					meta: {
						authRequired: true
					},
				},

				{
					name: 'TestPage',
					path: '/test',
					component: () => import('@/views/index/Test'),
					meta: {
						authRequired: true
					},
				},

				project,
				settings,
				profile,
				timer
			]
		},

		//empty layout
		auth,

		//error
		{
			path: '*',
			component: () => import('@/views/EmptyLayout'),
			children: [
				{
					path: '',
					component: () => import('@/views/empty/Error'),
				},
			],
		},
	]
})


router.beforeEach((to, from, next) => {
	let authenticated = store.getters['auth/authenticated'],
		metaRedirect = to.meta.redirect;

	if (to.meta.authRequired) console.log('authRequired', to.fullPath, 'authenticated: ' + authenticated);
	if (to.meta.logedoutRequired) console.log('logedoutRequired', to.fullPath, 'authenticated: ' + authenticated);

	if (to.meta.authRequired && !authenticated) router.push(metaRedirect || '/auth/login');
	if (to.meta.logedoutRequired && authenticated) router.push(metaRedirect || '/');

	next();
});

export default router;
