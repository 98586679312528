import { $http } from '../plugins/axios';
import __ from '../plugins/utils';
import log from '../plugins/log';

export default {
	namespaced: true,
	state: {
		ts_diff: 0,
		current_timer: null,
		currentTimerPassed: 0
	},
	getters: {
		timePassedFormat: (state) => (timePassed, format = {}) => {
			let hours = Math.floor( ( timePassed ) / ( 60 * 60 ) )
			let minutes = Math.floor( ( timePassed - hours * 3600 ) / ( 60 ) )
			let seconds = Math.floor( timePassed - hours * 3600 - minutes * 60 )

			let hoursFormatted = hours < 10 ? `0${hours}` : `${hours}`
			let minutesFormatted = minutes < 10 ? `0${minutes}` : `${minutes}`
			let secondsFormatted = seconds < 10 ? `0${seconds}` : `${seconds}`

			let time = []
			if(hours > 0 || format.hours) time.push(hoursFormatted) 
			if(hours > 0 || minutes > 0 || format.minutes) time.push(minutesFormatted) 
			if(hours > 0 || minutes > 0 || seconds > 0 || format.seconds) time.push(secondsFormatted) 

			return time.join(':')
		}
	},
	mutations: {
		parse_ts_response(state, [err, response]) {
			//can pass here any $http response with response.data.ts path
			if(err) return log.error('$store.timer.parse_ts_response', 'error', err)
			if(!response || !response.data || !response.data.ts) return log.error('$store.timer.parse_ts_response', 'wrong_response', response)

			log.debug('$store.timer.parse_ts_response', response.data);
			state.ts_diff = parseInt(new Date().getTime()/1000 - response.data.ts)
		},
		parse_timer_response(state, [err, response]) {
			function stopLocalTimer() {
				state.current_timer = null
			}

			if(err) return log.error('$store.timer.parse_timer_response', 'error', err)
			if(!response) 
				return stopLocalTimer()
			if(response.time_start != null && response.time_finish != null)
				return stopLocalTimer()	

			if(response.time_start != null && response.time_finish == null) {
				state.current_timer = response
			}
		},
		current_time_passed(state) {
			if(state.current_timer !== null)
				state.currentTimerPassed = parseInt( (new Date().getTime() - new Date(state.current_timer.time_start).getTime())/1000 + state.ts_diff )
			else 
				state.currentTimerPassed = 0
		}
	},
	actions: {
		init({ state, commit, dispatch }) {
			//call function to get current time from server now and in interval
			dispatch('get_api_ts');
			setInterval(() => {
				dispatch('get_api_ts');
			}, 5 * 60 * 1000)

			setInterval(() => {
				if(state.current_timer !== null || state.currentTimerPassed !== 0)
					commit('current_time_passed')
			}, 1000)
		},
		async get_api_ts({ commit }) {
			//get current ts from server
			const response = await __.to($http.get('/ts'));
			commit('parse_ts_response', response);
		},

		async startTimer({ dispatch, commit, rootState, state }, {$orm, object}) {
			if(state.current_timer)
				return false;

			dispatch('get_api_ts');
			
			const response = await $orm.mutate({
				timer: {
					insert: {
						objects: {
							user_id: rootState.auth.user.id,
							...object
						}
					}
				},
				...triggerUpdates(object)
			}, { getFirst: true })
			commit('parse_timer_response', response);
		},

		async finishTimer({ commit, state }, {$orm, object}) {
			if(!state.current_timer)
				return false;
			
			const response = await $orm.mutate({
				timer: {
					update: {
						where: { id: { _eq: state.current_timer.id }},
						_set: { time_finish: 'now()' }
					}
				},
				...triggerUpdates(state.current_timer)
			}, { getFirst: true })
			commit('parse_timer_response', response);
		}
	},
};

function triggerUpdates(object) {
	if(!object) return {}

	function dynUpdate(id) {
		return { update: { where: { id: { _eq: id } }, _set: { updated_at_trigger: true }, fields: ['id'] } }
	}
	let updater = {}
	if(object.project_id) updater.project = dynUpdate(object.project_id)
	if(object.task_id) updater.task = dynUpdate(object.task_id)
	if(object.subtask_id) updater.subtask = dynUpdate(object.subtask_id)

	return updater
}