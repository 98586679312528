export function generateTables(orm) {
    orm
		.createTable({ name: 'user' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'name' })
			.createField({ name: 'email' })
		.generateBaseFragments()
	
	orm
		.createTable({ name: 'user_private' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'name' })
			.createField({ name: 'email' })
			.createField({ name: 'timezone_offset' })
			.createField({ name: 'notification_settings' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'project' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'title' })
			.createField({ name: 'description' })
			.createField({ name: 'owner_id' })
			.createField({ name: 'created_at' })
			.createField({ name: 'is_finished' })
			.createField({ name: 'finished_by_id' })
			.createField({ name: 'finished_at' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'project_guest' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'project_id' })
			.createField({ name: 'user_id' })
			.createField({ name: 'is_confirmed' })
			.createField({ name: 'role' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'task' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'project_id' })
			.createField({ name: 'title' })
			.createField({ name: 'description' })
			.createField({ name: 'owner_id' })
			.createField({ name: 'created_at' })
			.createField({ name: 'is_finished' })
			.createField({ name: 'finished_by_id' })
			.createField({ name: 'finished_at' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'subtask' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'task_id' })
			.createField({ name: 'title' })
			.createField({ name: 'description' })
			.createField({ name: 'owner_id' })
			.createField({ name: 'created_at' })
			.createField({ name: 'is_finished' })
			.createField({ name: 'finished_by_id' })
			.createField({ name: 'finished_at' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'timer' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'project_id' })
			.createField({ name: 'task_id' })
			.createField({ name: 'subtask_id' })
			.createField({ name: 'user_id' })
			.createField({ name: 'time_start' })
			.createField({ name: 'time_finish' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'project_favorite' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'project_id' })
			.createField({ name: 'user_id' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'images' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'cdn_url' })
			.createField({ name: 'image_path' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'notification' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'created_at' })
			.createField({ name: 'processed_at' })
			.createField({ name: 'text' })
			.createField({ name: 'meta' })
			.createField({ name: 'type' })
        .generateBaseFragments()

    orm
		.createTable({ name: 'stats_matrix2' })
			.createField({ name: 'user_id' })
			.createField({ name: 'cdate' })
			.createField({ name: 'subtasks_finished' })
			.createField({ name: 'subtasks_created' })
			.createField({ name: 'tasks_finished' })
            .createField({ name: 'tasks_created' })
            .createField({ name: 'projects_finished' })
            .createField({ name: 'projects_created' })
            .createField({ name: 'timers_fired' })
            .createField({ name: 'time_spent' })
		.generateBaseFragments()
		
	orm
		.createTable({ name: 'project_category' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'user_id' })
			.createField({ name: 'title' })
		.generateBaseFragments()
	
	orm
		.createTable({ name: 'project2category' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'project_id' })
			.createField({ name: 'category_id' })
        .generateBaseFragments()
	
	orm
		.createTable({ name: 'project_color' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'project_id' })
			.createField({ name: 'user_id' })
			.createField({ name: 'color' })
        .generateBaseFragments()
		
		
    return orm
}