export default {
    path: '/profile',
    component: () => import('@/views/profile/Layout'),
    children: [
        {
            name: 'ProfileView',
            path: ':id',
            component: () => import('@/views/profile/View'),
            meta: {
                authRequired: true,
            },
        }
    ],
}