export default {
	namespaced: true,
	state: {
		backend_url: null,
		graphql_url: null,
		graphql_ws_url: null,
	},
	mutations: {
		set_backend_url: (state, url) => (state.backend_url = url),
		set_graphql_url: (state, url) => (state.graphql_url = url),
		set_graphql_ws_url: (state, url) => (state.graphql_ws_url = url),
		set_theme: (state, theme) => (state.theme = theme),
	},
	actions: {
		get_settings: async ({ rootState, commit }) => {
			var response = await fetch('/cfg/settings.json');
			response = await response.json();

			commit('set_backend_url', response.backend_url);
			commit('set_graphql_url', response.graphql_url);
			commit('set_graphql_ws_url', response.graphql_ws_url);

			if (!rootState.auth.jwt_token) {
				commit('auth/set_jwt_token', response.jwt_default, { root: true });
			}
		},
		setTheme: ({ commit }, themeName) => {
			commit('set_theme', themeName);
			window.location.reload();
		},
	},
};
