export default {
    path: '/timer',
    component: () => import('@/views/timer/Layout'),
    children: [
        {
            name: 'TimerList',
            path: ':project_id?/:task_id?/:subtask_id?',
            component: () => import('@/views/timer/View'),
            meta: {
                authRequired: true,
            },
        }
    ],
}