<template>
	<router-view></router-view>
</template>

<script>
import imageModalMixin from '@/mixins/image-modal'

export default {
	name: 'App',
	mixins: [
		imageModalMixin
	],
};
</script>