export default {
	namespaced: true,
	state: {
		meta: {},
		mobile_menu: false
	},
	mutations: {
		set_meta: (state, meta) => (state.meta = meta),
		mobile_menu_toggle: (state, status = null) => (state.mobile_menu = status === null ? !state.mobile_menu : !!status)
	}
};
